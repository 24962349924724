import axios from 'axios';

export const useAxios = () => {
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();
  const token = useCookie('token').value;
  const api = axios.create({
    baseURL: config.public.baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      'Accept-Language': nuxtApp.$i18n.locale._value,
    },
  });

  return {
    api,
  };
};
