import { useAxios } from '@/composables/useAxios';
export const state = () => ({
  loading: true,
  courseDetails: [],
  courseDetailsName: '',
  country: '',
  city: '',
  fund_price: '',
  level: '',
  requirements: '',
  currency: '',
  logo: '',
  favourite: false,
  courseDetailsList: [],
  keyWordCourse: '',
  keyWordCourseQuery: '',
  searchWord: '',
  universitiesDetails: [],
  name: '',
  ranking: '',
  description: '',
  services: null,
  image: '',
  ageGroup: '',
  dates: [],
  studyTimes: [],
  instituteId: '',
  accommodationList: [],
  university_courses_list: [[]],
  university_courses_list_page: [[]],
  per_page_list: 6,
  englishCoursesSchoolName: '',
});

export const actions = {
  restData({ state }) {
    state.loading = true;
    state.universitiesDetails = [];
    state.name = '';
    state.city = '';
    state.country = '';
    state.logo = '';
    state.ranking = '';
    state.description = '';
    state.accommodationList = [];
    state.services = null;
    state.image = '';
    state.university_courses_list = [[]];
  },
  getInstituteDetails({ state }, payload) {
    const { api } = useAxios();
    api.get(`/english-courses/${payload}`).then(res => {
      state.loading = false;
      state.courseDetails = res.data.data;
      state.courseDetailsName = res.data.data.name;
      state.country = res.data.data.country.name;
      state.city = res.data.data.city.name;
      state.fund_price = res.data.data.fees;
      state.fees = res.data.data.fees;
      state.application_fees = res.data.data.application_fees;
      state.institue_application_fees = res.data.data.institue_application_fees;
      state.level = res.data.data.level.name;
      state.description = res.data.data.description;
      state.services = res.data.data.services;
      state.accommodationList = res.data.data.accommodations;
      state.requirements = res.data.data.requirements;
      state.favourite = res.data.data.favourite;
      state.currency = res.data.data.currency;
      state.logo = res.data.data.logo;
      state.image = res.data.data.image;
      state.ageGroup = res.data.data.age_group;
      state.dates = res.data.data.dates;
      state.studyTimes = res.data.data.study_time;
      state.datesList = res.data.data.dates;
      state.instituteId = res.data.data.institute.id;
      state.englishCoursesSchoolName = res.data.data.institute.name;
    });
  },

  async getInstitutesDetails({ state }, payload) {
    const { api } = useAxios();
    await api.get(`/institutes/${payload}`).then(res => {
      state.loading = false;
      state.universitiesDetails = res.data.data;
      state.name = state.universitiesDetails.name;
      state.country = state.universitiesDetails.country.name;
      state.city = state.universitiesDetails.city.name;
      state.logo = state.universitiesDetails.logo;
      state.ranking = state.universitiesDetails.ranking;
      state.description = state.universitiesDetails.description;
      state.services = state.universitiesDetails.services;
      state.accommodationList = res.data.data.accommodations;
      state.image = state.universitiesDetails.image;
      state.global_application_fees = res.data.data.application_fees;
    });
  },
  async getInstitutesDetailsCoursesList({ state }, payload) {
    const keyword = useCookie('keyword');
    state.keyWordCourse = keyword.value;
    if (state.keyWordCourse) {
      state.keyWordCourseQuery = `&course_name_keyword=${state.keyWordCourse}`;
    } else {
      state.keyWordCourseQuery = '';
    }
    const { api } = useAxios();
    await api
      .get(
        `/english-courses?institute_id=${payload.id}&per_page=${state.per_page_list}${state.searchWord}${state.keyWordCourseQuery}`
      )
      .then(res => {
        state.university_courses_list = [
          res.data.data.map(item => ({
            id: item.id,
            type: 'courses',
            name: item.name,
            offer: item.fees,
            offerCount: item.sale_percentage,
            price: item.fees_after_sale,
            has_sale: item.has_sale,
            location: item.location,
            image: item.image,
            favourite: item.favourite,
            course_type: item.course_type,
            currency: item.currency,
          })),
        ];
      });
  },

  getInstituteDetailsAccommodation({ state }, payload) {
    const { api } = useAxios();
    api.get(`/institutes/${payload}`).then(res => {
      state.accommodationList = res.data.data.accommodations;
      state.servicesList = res.data.data.services;
      state.englishCoursesSchoolName = res.data.data.name;
    });
  },
};
