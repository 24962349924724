import { useAxios } from '@/composables/useAxios';
import { useI18n } from 'vue-i18n';

export const state = () => ({
  mapList: [],
  destinationsList: [],
  destinationsListUniversity: [],
  destinationsListEnglish: [],
  result: [],
  loading: false,
});

export const actions = {
  async getDestinations({ state }) {
    const { api } = useAxios();
    const { locale } = useI18n();
    state.loading = false;
    api
      .get(`/countries?is_map=1`, {
        headers: {
          'Accept-Language': locale.value,
        },
      })
      .then(res => {
        state.mapList = res.data.data.map(item => {
          return {
            id: item.id,
            name: item.name,
            code: item.code,
            image: item.image,
            query: `&country_ids[]=${item.id}`,
          };
        });
        const listUniversity = res.data.data.filter(item => item.is_university);
        state.destinationsListUniversity = listUniversity.map(item => {
          return {
            id: item.id,
            name: item.name,
            image: item.image,
            flag: item.flag,
            is_university: item.is_university,
            is_institute: item.is_institute,
          };
        });

        const list = res.data.data.filter(item => item.is_institute);
        state.destinationsListEnglish = list.map(item => {
          return {
            id: item.id,
            name: item.name,
            image: item.image,
            flag: item.flag,
            is_university: item.is_university,
            is_institute: item.is_institute,
          };
        });

        state.destinationsList = res.data.data.map(item => {
          return {
            id: item.id,
            flag: item.flag,
            show: item.flag,
            name: item.name,
            image: item.image,
            is_university: item.is_university,
            is_institute: item.is_institute,
          };
        });
        state.loading = true;
      });
  },
};
