<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script>
import { useFcm } from '@/composables/useFcm';

const { getFcm } = useFcm();
export default {
  async mounted() {
    this.$primevue.config.ripple = true;
    await this.$store.dispatch('countries/getCountriesRegistration');
    await getFcm();
  },
};
</script>
