import { useAxios } from '@/composables/useAxios';

export default defineNuxtPlugin(nuxtApp => {
  const userToken = useCookie('token');
  const { api } = useAxios();
  api.interceptors.request.use(
    function (config) {
      config.headers['Accept-Language'] = nuxtApp.$i18n.locale._value;
      if (userToken) {
        config.headers['Authorization'] = `Bearer ${userToken.value}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
});
